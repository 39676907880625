import React from "react";
import Layout from "../shared/components/organisms/layout/layout";
import RegisterContent from "../components/organisms/register-content/register-content";
import useMobie from '../shared/hooks/useMobile'
import useRegisterInfo from '../shared/hooks/useRegister'
import SEO from "../shared/components/atoms/seo/seo"
import '../shared/styles/index.scss';

const RegisterPage = (props) => {

  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);
  const isMobile = useMobie();

  const {registerInfo} = useRegisterInfo();

  return (
    <Layout activeMenu={5} modalActive={modalActive} shopButton={!isMobile}>
      <SEO
        title={registerInfo.metaTitulo ? registerInfo.metaTitulo : ''}
        description={registerInfo.metaDescripcion ? registerInfo?.metaDescripcion?.metaDescripcion : ''}
        slug={registerInfo.slug ? registerInfo.slug : ''}
        lang="ES"
      />
      <RegisterContent registerInfo={registerInfo} setModalActive={setModalActive} isMobile={isMobile} />
    </Layout>
  )
}

export default RegisterPage
