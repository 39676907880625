import React from 'react';
import homeLink from "../../../utils/siteUrl";
import './breadcrumb.scss';


const Breadcrumb = props => {

  const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toUpperCase();
    }).replace(/\s+/g, '').replace(/-/g, ' ');
  }

  const isBrowser = typeof window !== "undefined"

  const path = isBrowser ? window.location.pathname : "/"
  const elements = path.split("/")
  elements.shift()

  if (elements[elements.length - 1] === '') elements.pop()

  if (!props.mobile) {
    return (
      <div className="f-breadcrumb-container">
        <div className="f-breadcrumb-element">
          <a className="f-link f-link-breadcrumb" href={homeLink}>Home</a>
        </div>

        {elements.map((element, index) => {

          let fullPath = ""
          elements.map((elementPath, indexPath) => indexPath <= index ? fullPath += elementPath + '/' : '')

          if (index < elements.length - 1) {

            return (
              <div key={index} className="f-breadcrumb-element">
                <span className="f-breadcrumb-separator">/</span>
                <a className="f-link f-link-breadcrumb" href={homeLink + fullPath}>{camelize(element)}</a>
              </div>

            )

          } else {
            return (
              <div key={index} className="f-breadcrumb-element">
                <span className="f-breadcrumb-separator">/</span>
                <span className="f-current-site">{camelize(element)}</span>
              </div>

            )
          }
        })}
      </div>
    )
  } else {
    return null
  }
}

export default Breadcrumb
