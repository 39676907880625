import { useEffect } from 'react'
import { createPortal } from 'react-dom'

import './modal-portal.scss'
export default function ModalPortal({ children }) {

  const modalRoot =
    typeof document !== `undefined`
      ? document.getElementById('modal-root')
      : null

  const modalViewer =
    typeof document !== `undefined` ? document.createElement('div') : null
    modalViewer !== null && modalViewer.setAttribute("id", "modal-wrapper")

  useEffect(() => {
    if (!(modalRoot && modalViewer)) return

    modalRoot.appendChild(modalViewer)
    return () => {
      modalRoot.removeChild(modalViewer)
    }
  }, [modalRoot, modalViewer])

  if (modalRoot && modalViewer) return createPortal(children, modalViewer)
  return null
}
