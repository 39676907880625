import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import BreadCrumb from "../../../shared/components/atoms/breadcrumb/breadcrumb"
import Button from "../../../shared/components/atoms/button/button"
import CustomInputText from "../../../shared/components/atoms/inputText/InputText"
import { sessionStorage } from "../../../shared/utils/storage"
import AccountService from "../../../shared/services/account-service"
import homeLink from "../../../shared/utils/siteUrl"
import Loader from "react-loader-spinner"
import ModalGeneric from "../../../shared/components/modals/modal-generic/modal-generic"
import ModalPortal from "../../../shared/components/templates/modal-portal/modal-portal"
import userSessionStorage from "../../../shared/utils/userSessionStorage"
import {
  EMAIL_PATTERN,
  NUMBER_PATTERN,
  successStatus,
} from "../../../shared/constants/appConstants"
import "./register-content.scss"

const RegisterContent = props => {
  const session = new userSessionStorage()
  const { registerInfo, setModalActive, isMobile } = props
  const {
    banners,
    tituloIntroductorio,
    descripcionIntroductoria,
  } = registerInfo

  const [defaultData] = useState(sessionStorage.getItem("infoRegister"))
  const [loader, setLoader] = useState(false)
  const [modalInfo, setModalInfo] = useState()
  const [isRegister, setIsRegister] = useState(false)

  const {
    register,
    handleSubmit,
    formState,
    errors,
    clearErrors,
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      username: defaultData
        ? defaultData.email
          ? defaultData.email
          : defaultData.cellphone
          ? defaultData.cellphone
          : ""
        : "",
    },
  })

  const handleModal = ({
    title = "Ocurrio un error inesperado",
    text = "Ocurrio un error inesperado, por favor intentalo más tarde",
    type = "warning",
    buttonConfirm = false,
    onConfirm = () => {},
    onClose = () => setModalInfo(null),
  }) => {
    setModalInfo({
      title,
      text,
      type,
      buttonConfirm,
      onConfirm: onConfirm,
      onClose: () => {
        setModalInfo(null)
        onClose && onClose()
      },
    })
  }

  const handleValidateEmail = () => {
    let username = watch("username")
    let cellLength = process.env.CELL_DIG ? process.env.CELL_DIG : 10

    if (
      !EMAIL_PATTERN.test(username.toString()) &&
      NUMBER_PATTERN.test(username.toString()) &&
      username.length === Number(cellLength)
    ) {
      clearErrors()
      return true
    } else if (
      !EMAIL_PATTERN.test(username.toString()) &&
      NUMBER_PATTERN.test(username.toString()) &&
      username.length >= 8 &&
      username.length !== Number(cellLength)
    ) {
      setError("username", {
        type: "manual",
        message: "Un celular requiere " + cellLength + " caracteres",
      })
    } else if (EMAIL_PATTERN.test(username.toString())) {
      clearErrors()
      return true
    } else {
      setError("username", {
        type: "manual",
        message: "Campo inválido",
      })
    }
  }

  const onSubmit = async data => {
    //validate input for email or cellphone
    if ((await handleValidateEmail()) === false) return false

    setLoader(true)

    let cellLength = process.env.CELL_DIG ? process.env.CELL_DIG : 10
    let infoRegister = sessionStorage.getItem("infoRegister")
    let isMail = true
    let username

    if (
      !EMAIL_PATTERN.test(data.username.toString()) &&
      NUMBER_PATTERN.test(data.username.toString()) &&
      data.username.length === Number(cellLength)
    ) {
      username = process.env.COUNTRY_PHONE_PREFIX
        ? process.env.COUNTRY_PHONE_PREFIX +
          data.username.trim().replace("  ", " ")
        : "+57" + data.username.trim().replace("  ", " ")
      infoRegister.cellphone = data.username.trim().replace("  ", " ")
      infoRegister.email = ""
      isMail = false
    } else {
      username = data.username.trim().replace("  ", " ")
      infoRegister.email = data.username.trim().replace("  ", " ")
      infoRegister.cellphone = ""
    }

    let payload = {
      username: username,
    }

    infoRegister?.userId && (payload.userId = infoRegister?.userId)

    AccountService.validateUser(payload)
      .then(res => {
        if (res.status === 400 && res.errorCode === "DEM029") {
          sessionStorage.setItem(
            "infoRegister",
            isMail ? { email: username } : { cellphone: session.getNoPrefix(username) }
          )
          window.location.href = homeLink + "registro/paso-1/"
        } else if (res.errorCode === "DIM013" || res.errorCode === "DEM005") {
          setIsRegister(true)
          setError("username", {
            type: "manual",
            message: res.userMessage,
          })
        } else if (res.errorCode === "DEM030") {
          handleModal({
            title: "¡UPS! Este es un ladrido de error",
            text: "Recuerda activar tu cuenta para iniciar sesión",
            buttonConfirm: "Aceptar",
            onConfirm: () =>
              (window.location.href =
                homeLink + "registro/confirmacion/?n=" + res.data.userId),
            onClose: () =>
              (window.location.href =
                homeLink + "registro/confirmacion/?n=" + res.data.userId),
          })
        } else if (res.errorCode === "DEM043") {
          sessionStorage.setItem("infoRegister", res.data)
          sessionStorage.setItem("initialPets", res.data.pets)
          window.location.href = homeLink + "registro/paso-1/"
        } else {
          setError("username", {
            type: "manual",
            message: res.userMessage,
          })
        }
      })
      .finally(() => setLoader(false))
      .catch(error => {
        console.log(error)
        setLoader(false)
      })
  }

  /*Banner presentations configuration*/
  const settings = {
    autoplay: isMobile,
    dots: true,
    // dots: !isMobile,
    autoplaySpeed: 3000,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
  }

  const listItems =
    banners &&
    banners.map((banner, key) => {
      return (
        <div className="f-slide-container" key={key}>
          <figure className="f-slider-img">
            <img
              src={banner.banners[isMobile ? 1 : 0].file.url}
              alt={banner.banners[isMobile ? 1 : 0].description}
            />
          </figure>
          <div className="f-breadcrum-position">
            <BreadCrumb mobile={isMobile} />
          </div>
          <div className="f-slide-title-content">
            <h2 className="f-slide-title">{banner.tituloBanner}</h2>
            <p className="f-slide-description">
              {banner.descripcionBanner.descripcionBanner}
            </p>
          </div>
        </div>
      )
    })

  useEffect(() => {
    //if sesión storage is null create a object
    !sessionStorage.getItem("infoRegister") &&
      sessionStorage.setItem("infoRegister", {})
    sessionStorage.removeItem("initialPets")

    //validate email default or cellphone default
    if (defaultData && defaultData.email) {
      handleValidateEmail("email", defaultData.email)
    } else if (defaultData && defaultData.cellphone) {
      handleValidateEmail("cellphone", defaultData.cellphone)
    }
  }, [])

  return (
    <div className="f-main-register">
      {modalInfo && (
        <ModalPortal>
          <ModalGeneric
            title={modalInfo.title}
            text={modalInfo.text}
            type={modalInfo.type}
            onClose={modalInfo.onClose}
            buttonConfirm={modalInfo.buttonConfirm}
            onConfirm={modalInfo.onConfirm}
          />
        </ModalPortal>
      )}
      <div className="f-register-header">
        <Slider {...settings} className="f-slider-register">
          {listItems}
        </Slider>
      </div>
      <div className="f-register-content">
        <h1 className="f-title--purple f-register-title">
          {tituloIntroductorio}
        </h1>
        <p className="f-text-regular f-register-description">
          {descripcionIntroductoria.descripcionIntroductoria}
        </p>
        <form
          className="f-register-form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="f-column">
            <div
              className={
                isRegister
                  ? "f-container-input f-input-blue"
                  : "f-container-input"
              }
            >
              <CustomInputText
                fullWidth={true}
                label="Ingresa tu correo o número de celular"
                id="username-input"
                required={true}
                onBlur={handleValidateEmail}
                onChange={() => setIsRegister(false)}
                inputProps={{
                  name: "username",
                  maxLength: 100,
                  placeholder: "Ingresa celular o correo electrónico",
                }}
                inputRef={register({
                  required: "El campo es requerido.",
                  minLength: {
                    value: 8,
                    message: "Se requieren mínimo 8 caracteres.",
                  },
                  maxLength: {
                    value: 50,
                    message: "Se requieren máximo 50 caracteres.",
                  },
                })}
                error={errors.username ? true : false}
                errorText={
                  errors.username
                    ? errors.username.message
                      ? errors.username.message
                      : "Campo inválido"
                    : ""
                }
              />
            </div>

            {loader ? (
              <Loader type="TailSpin" color="#5C2D90" height={50} width={50} />
            ) : (
              <Button
                className="f-primary-purple f-next-button"
                typeButton={isRegister ? "link" : "button"}
                type={isRegister ? "link" : "submit"}
                title={isRegister ? "Iniciar sesión" : "Siguiente"}
                disabled={isRegister ? false : !formState.isValid}
                href={"mi-cuenta/autenticacion"}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default RegisterContent
